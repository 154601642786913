<template>
  <div class="container-fluid">
    <b-row class="my-1">
      <b-col md="8" class="ml-n3" v-if="offerData1.data && offerData1.data[0].url">
        <a :href="offerData1.data[0].navigateTo">
          <b-img :src="offerData1.data[0].url.fileUrl" class="width height"></b-img>
        </a>
      </b-col>
      <b-col md="4" class="ml-n4" v-if="offerData1.data && offerData1.data[1].url">
        <a :href="offerData1.data[1].navigateTo">
          <b-img :src="offerData1.data[1].url.fileUrl" class="width1 height"></b-img>
        </a>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "middle-Offer",
  components: {},
  data() {
    return {};
  },

  computed: {
    ...mapState({
      offerData1: ({ advertisement }) => advertisement.offer1,
    }),
  },

  methods: {
    ...mapActions({
      offer1: "advertisement/offerData1",
    }),
  },

  mounted() {
    this.offer1();
  },
};
</script>

<style>
.width {
  width: 100% !important;
}
.height {
  height: 100% !important;
}
.width1 {
  width: 115% !important;
}
</style>
