var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid"
  }, [_c('b-row', {
    staticClass: "my-1"
  }, [_vm.offerData1.data && _vm.offerData1.data[0].url ? _c('b-col', {
    staticClass: "ml-n3",
    attrs: {
      "md": "8"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.offerData1.data[0].navigateTo
    }
  }, [_c('b-img', {
    staticClass: "width height",
    attrs: {
      "src": _vm.offerData1.data[0].url.fileUrl
    }
  })], 1)]) : _vm._e(), _vm.offerData1.data && _vm.offerData1.data[1].url ? _c('b-col', {
    staticClass: "ml-n4",
    attrs: {
      "md": "4"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.offerData1.data[1].navigateTo
    }
  }, [_c('b-img', {
    staticClass: "width1 height",
    attrs: {
      "src": _vm.offerData1.data[1].url.fileUrl
    }
  })], 1)]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }